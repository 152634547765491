@media screen and (max-width: 750px) {
    body {
        & > section {
            width: 100%;
        }
    }

    .header {
        height: 45.25rem;
        background: url('@{image_root}/web/cgms/top_bg_mobile_2v2.jpg') no-repeat center;
        background-size: auto 100%;

        .background {
            height: 45.25rem;
            overflow: hidden;
        }

        .centered {
            padding-left: 2.5rem;
            padding-top: 2.5rem;

            h1 {
                width: 18.4375rem;
                font-size: 1.625rem;
            }
            h3{
                margin-top:0.25rem;
                width: 18.4375rem;
                font-size: 0.875rem;
            }
        }

        .button_container {
            width: 18.4375rem;
            padding-left: 0;
            flex-direction: column;
            gap: 1rem;

            .buy_btn, .register_btn, .download_brochure_btn {
                width: 100%;

                padding: 0.25rem 0;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2rem; /* 200% */
            }
        }
    }

    .why_buzud_cgms {
        .block-container {
            display: flex;
            flex-direction: column;
            padding: 0 0.75rem;

            .block {
                width: 21.9375rem;

                .top {
                    width: 21.9375rem;
                    overflow: hidden;

                    img {
                        height: 15rem;

                        position: relative;
                        left: 70%;
                        transform: translateX(-70%);

                        &.keep-left {
                            left: 30%;
                            transform: translateX(-30%);
                        }

                        &.keep-left-a-little {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    h3 {
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2rem; /* 133.333% */
                        letter-spacing: -0.006rem;

                        width: 14.125rem;

                        &.middle-width {
                            width: 14.125rem;
                        }

                        &.narrow-width {
                            width: 14.125rem;
                        }
                    }
                }
            }
        }
    }

    .intro_videos {
        .content {
            width: 100%;
            padding: 0;
            background: none;
            margin: 1.5rem auto;

            .title {
                font-size: 2rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2.75rem; /* 137.5% */
                letter-spacing: -0.008rem;

                margin-bottom: 1rem;
            }


            .tips {
                width: 100%;
                gap: 1.5rem;
                flex-direction: column;
                align-items: center;

                img {
                    width: 12.625rem;
                    height: 12.625rem;
                    aspect-ratio: 1;
                }
            }
        }
    }

    .sensor_introduction {
        overflow: hidden;
        padding: 1.5rem 0 1.5rem;

        .title {
            font-size: 2rem;
            font-weight: 500;
            line-height: 2.75rem;
            letter-spacing: -0.008rem;
            margin-top: 3rem;
        }

        .setup-steps {
            margin: 1.5rem auto;

            .steps {
                flex-direction: column;
                align-items: center;
                gap: 1.5rem;
            }
        }

        .card_container {
            margin: 2.5rem auto;

            .cards {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 0 0.75rem;


                .card {
                    width: 100%;

                    padding: 0 0 1.5rem;

                    .top {
                        height: 20rem;

                        img {
                            width: 16.9475rem;
                            margin: 2.5rem auto 0;
                        }
                    }

                    .title {
                        margin: 1.5rem auto 0;
                        text-align: center;
                        padding: 0 1.5rem;
                    }

                    .desc {
                        margin: 0.75rem auto 0;
                        padding: 0 1.5rem;
                    }

                }
            }
        }

        .tips_for_performance {
            width: auto;
            margin: 1.5rem 0.75rem 0;
            padding: 1.5rem;

            h3 {
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem; /* 133.333% */
                letter-spacing: -0.006rem;
                text-align: center;
            }

            ul {
                width: auto;
                margin: 1.5rem auto 0;
            }
        }

        .user_feedbacks {
            margin-top: 2.5rem;
            padding: 0 0.75rem;

            h3 {
                font-size: 2rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2.75rem; /* 137.5% */
                letter-spacing: -0.008rem;
            }

            .carousel-container {
                width: 22rem;
                margin-top:1.5rem;

                .carousel-item {
                    width: 22rem;

                    .content {
                        gap: 1rem;

                        .comment {
                            max-width: 16.9375rem;

                            h4 {
                                font-size: 1.5rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 2rem; /* 133.333% */
                                letter-spacing: -0.006rem;
                            }

                            p {
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: -0.0035rem;

                                &.user {
                                    margin-top: 0.5rem;
                                }
                            }
                        }

                        .initial {
                            align-self: flex-start;
                            transform: translateY(30%);
                        }

                        .final {
                            align-self: flex-end;
                            transform: translateY(-80%);
                        }
                    }
                }
            }
        }
    }

    .packet-plans {
        height: auto;
        width: 100%;
        padding: 0;

        .content {
            width: 100%;
            margin: 0 auto;
            padding-bottom: 1.5rem;

            .title {
                padding: 1.5rem 0;
                font-size: 2rem;
                letter-spacing: -0.008rem;
            }

            .guide-contact {
                border-radius: 0.5rem;
                width: 21.9375rem;
                padding: 1rem 1rem;
                text-align: center;

                flex-direction: column;
                gap: 1rem;

                .guide {

                    max-width: inherit;

                    p {
                        padding: 0 0.5rem;
                        text-align: left;
                    }
                }

                .contact {
                    padding-right: 0;

                    a {
                        color: #0E0F10;
                    }
                }

                h3 {
                    font-size: 1.0625rem;
                    line-height: 1.5rem; /* 141.176% */
                    letter-spacing: -0.00425rem;
                }

                p {
                    margin-top: 0.5rem;

                }
            }

            .plans {
                margin-top: 1.5rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;

                .plan {
                    border-radius: 0.5rem;
                    padding-top: 1.5rem;
                    width: 21.9375rem;
                    height: auto;


                    .buy {
                        position: static;
                        margin-top: 3rem;
                        width: 100%;


                        .price {
                            font-size: 1.5rem;
                            line-height: 2rem; /* 137.5% */
                        }

                        .gst {
                            font-size: 0.75rem;
                            letter-spacing: -0.003rem;
                        }

                        .buy-now {
                            padding: 0.5rem 0.935rem;

                        }
                    }
                }
            }
        }
    }

    .doctor-registration {
        background-size: auto 100%;

        .doctor {
            display: none;
        }

        .content {
            width: fit-content;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 0.75rem;

            .title {
                font-size: 2rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2.75rem; /* 137.5% */
                letter-spacing: -0.008rem;


            }

            .desc {
                width: fit-content;
            }

            .form {
                width: auto;

                .input-submit {
                    text-align: center;
                }
            }


        }
    }

    .manual_download {
        width: 100%;
        padding: 2.5rem 0.75rem;

        .title {
            text-align: center;
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2.75rem; /* 137.5% */
            letter-spacing: -0.008rem;
        }

        .product_list {
            flex-direction: column;
            width: 100%;

            .product {
                width: 21.9375rem;
                height: auto;

                h2 {
                    text-align: center;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2rem; /* 133.333% */
                    letter-spacing: -0.006rem;
                }

                p {

                    img {
                        display: block;
                        height: 10rem;
                    }
                }

                .download_btn {
                    margin: 2.5rem auto 0;
                    width: 17rem;
                    padding: 0.75rem 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0.5rem;
                    background: linear-gradient(180deg, #0081DE 0%, #0067B2 100%);
                    box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;


                    cursor: pointer;
                }
            }
        }
    }

    .control_diabetes {
        width: 100%;
        padding: 1.5rem 0.75rem;
        text-align: center;

        .title {
            width: 100%;
            margin: 0 auto;
            font-size: 2rem;
            font-weight: 500;
            letter-spacing: -0.008rem;
        }

        .desc {
            width: 100%;
            margin: 1rem auto;
        }
    }


}