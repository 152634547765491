@media screen and (max-width: 1280px) and (min-width: 750px) {
    body {
        & > section {
            width: 100%;
        }
    }

    .header {
        height: 43rem;

        .centered {
            width: 64rem;
            padding-left: 5rem;
            padding-top: 7.5rem;

            h3 {
                width: 28rem;
            }
        }

        .button_container {
            width: 64rem;
            padding-left: 6rem;
            margin-top:3rem;
        }
    }

    .why_buzud_cgms {
        .block-container {
            padding: 0 1.5rem;

            .block {
                width: 30rem;

                .top {
                    width: 30rem;
                    overflow: hidden;
                    
                    img {
                        height: 15rem;
                        position:relative;
                        left:50%;
                        transform:translateX(-50%);
                        
                        &.keep-left{
                            left:0;
                            transform:none;
                        }
                        &.keep-left-a-little{
                            left:40%;
                            transform:translateX(-40%);
                        }
                    }
                    h3 {
                        width: 21.375rem;
                        
                        &.middle-width {
                            width: 20.6875rem;
                        }
                        &.narrow-width {
                            width: 17.5rem;
                        }
                    }
                }
            }
        }
    }

    .intro_videos {
        .content{
            width:auto;
            .tips {
                width: 61rem;

                gap: 1.75rem;

                .tip {
                    width: 12.625rem;
                    img {
                        width: 12.625rem;
                        height: 12.625rem;
                        aspect-ratio: 1;
                    }
                }
            }
        }
    }

    .sensor_introduction {
       .setup-steps{
           overflow-x: scroll;
           padding:0 1.5rem;
           
           &::-webkit-scrollbar {
               display: none;
           }
           .steps{
               width: fit-content;
           }
       }
        
       .card_container{
           overflow-x: scroll;
           padding:0 1.5rem;
           
           &::-webkit-scrollbar {
               display: none;
           }
           .cards{
               width: fit-content;
           }
       }
        
        .tips_for_performance{
            width: 61rem;
        }

        .user_feedbacks {
            .carousel-container {
                width: 61rem;
                .carousel-item {
                    width: 61rem;
                }
            }
        }
    }

    .packet-plans {
        height: 55.3rem;

        .content {
            width: 61rem;


            .guide-contact {

                .guide {
                    max-width: 28.125rem;
                }

            }

            .plans {
                gap: 1.56rem;

                .plan {
                    padding-top: 1.5rem;
                    width: 19.3125rem;
                    height: 30.875rem;
                    overflow: hidden;
                    text-align: center;
                    position: relative;

                    .buy {


                        .price {
                            font-size: 1.5rem;
                            line-height: 2rem; /* 133.333% */
                            letter-spacing: -0.006rem;
                        }

                        .gst {
                            font-size: 0.75rem;
                            line-height: 1rem; /* 133.333% */
                            letter-spacing: -0.003rem;
                        }

                        .buy-now {
                            padding: 0.5rem 0.9375rem;
                        }
                    }
                }
            }
        }
    }

    .doctor-registration {
        .doctor {
            display: none;
        }

        .content {
            width: fit-content;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            .desc {
                width: fit-content;
                max-width: 59rem;
            }

            .input-submit {
                text-align: center;
            }
        }
    }
}