@charset "utf-8";
@import "~@styles/common/variables.less";


.header {
    height: 37.75rem;
    background: url('@{image_root}/web/cgms/top_bg_2v2.jpg') no-repeat center;
    background-size: auto 100%;

    .centered {
        width: 80rem;
        margin: 0 auto;
        padding-left: 4.5625rem;
        padding-top: 7.5rem;
        color: #084999;

        h1 {
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            width: 36.25rem;
        }

        h3 {
            margin-top: 0.5rem;
            width: 31rem;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .button_container {
        width: 80rem;
        margin: 2.5rem auto 0;
        padding-left: 4.5625rem;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;

        .buy_btn {
            border: none;
            border-radius: 0.5rem;
            background: linear-gradient(180deg, #00CCB4 0%, #02A895 100%);
            box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;

            padding: 0.75rem 3.125rem;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2rem; /* 177.778% */
            color: #fff;
            cursor: pointer;
        }

        .register_btn, .download_brochure_btn {
            border: none;
            border-radius: 0.5rem;
            background: linear-gradient(180deg, #0081DE 0%, #0067B2 100%);
            box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;

            padding: 0.75rem 1rem;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2rem; /* 177.778% */
            color: #fff;
            cursor: pointer;

            text-transform: uppercase;

        }

    }
}


body > section {
    width: 80rem;
    padding: 1.5rem;
    margin: 0 auto;
}

.page_desc {
    .top_bar {
        display: block;
        width: 4rem;
        height: 0.5rem;
        background: #00CCB4;
        margin-bottom: 0.75rem;
    }

    .desc {
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: 148%;
    }
}

.why_buzud_cgms {
    padding: 4rem 0 0;

    .title {
        color: #00A9CE;
        font-size: 3rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 3rem;
    }

    .block-container {
        max-width: 77rem;
        margin: 0 auto;
        display: grid;
        gap: 1rem;
        grid-template-columns:  1fr 1fr;


        .block {
            background: #F1F4F6;
            display: flex;
            align-items: center;
            flex-direction: column;

            .top {
                position: relative;

                img {
                    display: block;
                    width: 38rem;
                    height: 15rem;
                }

                h3 {
                    position: absolute;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2.75rem; /* 137.5% */
                    letter-spacing: -0.008rem;
                    color: #fff;
                    left: 1.5rem;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 25rem;

                    &.middle-width {
                        width: 20.75rem;
                    }

                    &.narrow-width {
                        width: 17.5rem;
                    }
                }
            }

            .content {
                flex: 1;
                padding: 1rem 1.5rem 0.5rem;
                font-size: 1.0625rem;
                line-height: 1.5;
                color: #4A4A4A;


                p {
                    margin-bottom: 1rem;
                }

                ul {
                    list-style: disc;
                    margin-left: 1.5rem;
                    margin-bottom: 1rem;

                    li {
                        margin-bottom: 0.5rem;
                    }
                }
            }


        }

    }
}

.intro_videos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('@{image_root}/web/cgms/index/video_bg.png') no-repeat bottom center;
    background-size: auto 28.125rem;
    padding: 0 1.5rem;
    text-align: center;

    .content {
        width: 77rem;
        margin: 1rem auto 2.5rem;
        padding: 2.5rem 0;
        background-color: #F3F6F7;

        .title {
            font-size: 3rem;
            font-weight: 500;
            color: #0E0F10;
            margin-bottom: 2.5rem;
        }

        .tips {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            gap: 3rem;

            .tip {
                display: flex;
                flex-direction: column;
                align-items: center;

                width: 14.625rem;

                img {
                    display: block;
                    width: 14.625rem;
                    height: 14.625rem;
                    margin-bottom: 1rem;
                    border-radius: 1.5rem;
                    overflow: hidden;
                }

                h3 {
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: #0E0F10;
                }
            }
        }
    }


}


.sensor_introduction {
    width: 100%;
    padding: 2.5rem 0;
    background: #084999;
    color: #F1F0EF;

    .title {
        font-size: 3rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.012rem;
        text-align: center;
        margin-top: 5rem;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .description {
        font-size: 1rem;
        margin: 0 auto;
        padding: 1.5rem 0;
        width: 77rem;
    }

    .setup-steps {
        margin: 2.5rem auto;

        .steps {
            display: flex;
            flex-direction: row;
            gap: 0.94rem;
            justify-content: center;
            align-items: flex-start;

            .step {
                display: flex;
                flex-direction: column;
                align-items: center;

                width: 14.625rem;

                img {
                    background: #fff;
                    display: block;
                    width: 14.625rem;
                    height: 14.625rem;
                    margin-bottom: 0.75rem;
                    border-radius: 1.5rem;
                    overflow: hidden;
                }

                h3 {
                    max-width: 14.625rem;
                    font-size: 1.0625rem;
                    text-align: center;
                }
            }
        }
    }

    .card_container {
        .cards {
            width: 77rem;
            margin: 0 auto;

            display: grid;
            grid-template-columns:  1fr 1fr;
            gap: 1rem;

            &::-webkit-scrollbar {
                display: none;
            }

            .card {
                width: 38rem;
                border-radius: 2.5rem;
                overflow: hidden;
                background: #F1F4F6;
                color: var(--txt-color, #0E0F10);

                .top {
                    width: 100%;
                    height: 20rem;
                    overflow: hidden;
                    background-color: var(--bg-color, #fff);

                    img {
                        display: block;
                        margin: 1.5rem auto;
                        width: 27.9375rem;
                    }
                }


                .title {
                    width: 100%;
                    margin: 1.5rem 2.5rem 0;

                    font-size: 1.5rem;
                    font-weight: 500;
                    letter-spacing: -0.005rem;
                    text-align: left;
                }

                .desc {
                    margin: 1.5rem 2.5rem 0;
                    font-size: 1.0625rem;
                    line-height: 1.5rem;
                    padding-bottom: 1.5rem;

                    ol {
                        padding-left: 1rem;

                        li {
                            padding-left: 0.5rem;
                            list-style-type: decimal;
                            list-style-position: outside;
                        }
                    }
                }
            }
        }
    }

    .tips_for_performance {
        margin: 4rem auto 0;
        background: #fff;
        color: #0E0F10;
        border-radius: 2.5rem;
        width: 77rem;

        padding: 2.5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-size: 3rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.012rem;
        }

        ul {
            width: 40rem;
            margin-top: 1.5rem;
            padding-left: 1rem;

            li {
                padding-left: 0.5rem;
                list-style-type: disc;
                list-style-position: outside;
            }
        }
    }

    .user_feedbacks {
        margin-top: 4rem;
        color: #fff;

        & > h3 {
            font-size: 3rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.012rem;
            margin: 0 auto;
            text-align: center;
        }

        .carousel-container {
            width: 77rem;
            height: 13.75rem;
            display: block;
            overflow-x: scroll;
            margin: 0 auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .carousel {
                display: flex;
                flex-direction: row;
                width: fit-content;
            }

            .carousel-item {
                float: left;
                width: 77rem;
                height: 13.75rem;
                overflow-x: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                .content {
                    display: flex;
                    flex-direction: row;
                    gap: 1.5rem;

                    .comment {
                        max-width: 40rem;
                        display: flex;
                        flex-direction: column;

                        h4 {
                            font-size: 2rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 2.75rem; /* 137.5% */
                            letter-spacing: -0.008rem;
                        }

                        p {
                            margin-top: 1rem;
                            font-size: 1.0625rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.5rem; /* 141.176% */
                            letter-spacing: -0.00425rem;

                            &.user {
                                margin-top: 0.5rem;
                            }
                        }
                    }

                    .initial {
                        align-self: flex-start;
                        transform: translateY(30%);
                    }

                    .final {
                        align-self: flex-end;
                        transform: translateY(-80%);
                    }
                }
            }

        }

        .pager {
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;

            .dot {
                display: block;
                width: 0.375rem;
                height: 0.375rem;
                border-radius: 50%;
                background: #fff;
                cursor: pointer;

                &.active {
                    background: #00CCB4;
                }
            }
        }
    }
}


.packet-plans {
    background-size: auto 100%;
    background-color: #93bfdc;
    background: url('@{image_root}/web/cgms/index/package-plan-bg.jpg') no-repeat center;
    height: 39rem;
    width: 100%;
    padding: 0;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #0E0F10;
        width: 77rem;
        margin: 0 auto;

        .title {
            padding: 2.5rem 0;
            font-size: 3rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.012rem;
        }

        .plans {
            display: flex;
            flex-direction: row;
            gap: 2.5rem;

            .plan {
                background-color: #fff;
                border-radius: 1.5rem;
                padding-top: 1.5rem;
                width: 24rem;
                height: 27.9rem;
                overflow: hidden;
                text-align: center;
                position: relative;

                h3 {
                    padding: 0 1.5rem;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2.75rem; /* 137.5% */
                    letter-spacing: -0.008rem;
                    text-align: center;
                }

                .period {
                    padding: 0 1.5rem;
                    font-size: 3rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.012rem;
                    color: #00CCB4;
                }

                .replacements {
                    margin-top: 0.5rem;
                    padding: 0 1.5rem;
                    font-size: 1.0625rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.5rem; /* 141.176% */
                    letter-spacing: -0.00425rem;
                }

                .divider {
                    border-bottom: none;
                    border-color: rgba(14, 15, 16, 0.16);
                    margin: 1.5rem 1.5rem;
                }

                .desc {
                    padding: 0 1.5rem;
                    text-align: left;
                    font-size: 1.0625rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem; /* 141.176% */
                    letter-spacing: -0.00425rem;
                }

                .buy {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 6rem;
                    background-color: #F1F4F6;

                    padding: 0 1.5rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .pricing {
                        text-align: left;


                    }

                    .price {
                        font-size: 2rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2.75rem; /* 137.5% */
                        letter-spacing: -0.008rem;
                        position: relative;

                        .tips {
                            position: absolute;
                            width: 2.5rem;
                            height: 2.5rem;
                            right: -2.5rem;
                            top: -1.5rem;
                        }
                    }

                    .gst {
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.0035rem;
                    }

                    .buy-now {
                        border: none;
                        border-radius: 0.5rem;
                        background: linear-gradient(180deg, #00CCB4 0%, #02A895 100%);
                        box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;
                        padding: 0.5rem 1.875rem;
                        text-shadow: 0 0.0625rem 0.1875rem #079685;
                        font-size: 1rem;
                        font-weight: 700;
                        line-height: 2rem; /* 200% */
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.doctor-registration {
    background-size: auto 100%;
    background-color: #93bfdc;
    background: url('@{image_root}/web/cgms/index/doc_reg.jpg') no-repeat center;
    width: 100%;
    padding: 0;

    position: relative;
    padding-bottom: 2.5rem;

    .doctor {
        position: absolute;
        bottom: 0;
        left: 50%;
        overflow: hidden;

        .img {
            display: block;
            width: 50vw;
        }
    }

    .content {
        width: 77rem;
        margin: 0 auto;
        color: #0E0F10;


        .title {
            padding-top: 2.5rem;
            font-size: 3rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.012rem;

            text-align: center;
        }

        .desc {
            margin-top: 1rem;
            width: 35rem;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 148%; /* 1.48rem */
        }

        .form {
            margin-top: 2.5rem;
            width: 35rem;

            .input {
                .error-message {
                    display: none;
                }

                &.error {
                    input {
                        border-color: #f55;
                    }

                    .error-message {
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -1.5rem;
                        font-size: 1.0625rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.5rem; /* 141.176% */
                        letter-spacing: -0.00425rem;
                        color: #f55;
                    }
                }
            }

            .input-submit {
                margin-top: 1.5rem;

                .error-message {
                    display: none;
                }

                &.error {
                    input {
                        border-color: #f55;
                    }

                    .error-message {
                        display: block;
                        font-size: 1.0625rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.5rem; /* 141.176% */
                        letter-spacing: -0.00425rem;
                        color: #f55;
                    }
                }
            }

            .submit {
                border: none;
                border-radius: 0.5rem;
                background: linear-gradient(180deg, #0081DE 0%, #0067B2 100%);
                box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;
                padding: 0.75rem 1rem;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2rem; /* 177.778% */
                color: #fff;
                cursor: pointer;
                text-transform: uppercase;

            }
        }
    }

}

.control_diabetes {
    width: 100%;
    padding: 2.5rem;
    background: #084999;
    color: #F1F0EF;
    text-align: center;

    .title {
        width: 50rem;
        margin: 0 auto;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: -0.008rem;
    }

    .desc {
        width: 50rem;
        margin: 1.5rem auto;
        font-size: 1rem;
        line-height: 148%;
    }
}

.pop {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    .content {
        position: relative;
        background-color: #fefefe;
        margin: 15vh auto;
        padding: 1.5rem;
        border: none;
        width: 29.5rem;
        max-width: 100%;
        border-radius: 1rem;
        z-index: 200;
        max-height: 70vh;
        overflow-y: auto;
    }
}

.register-success {
    text-align: center;

    .title {
        font-size: 2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.75rem; /* 137.5% */
        letter-spacing: -0.008rem;
        color: #00CCB4;

    }

    .desc {
        margin-top: 0.75rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 148%; /* 1.48rem */
        color: #0E0F10;
        text-align: left;

    }

    .confirm {
        margin: 1.5rem auto 0;

        .confirm-btn {
            min-width: 15.75rem;;
            padding: 0.5rem 0;
            border-radius: 0.5rem;
            border: none;
            background: linear-gradient(180deg, #00CCB4 0%, #02A895 100%);
            box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;

            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2rem; /* 200% */

            color: #FFF;
            text-align: center;
            text-shadow: 0 0.0625rem 0.1875rem #079685;

            cursor: pointer;
        }
    ;
    }
}

.layui-input-group {
    border: none;
    width: 100%;
    border-radius: 0.5rem;

    margin-top: 1.5rem;
    padding: 0;

    transition: none;
    -webkit-transition: none;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 141.176% */
    letter-spacing: -0.00425rem;
    cursor: inherit;


    .layui-input-prefix {
        padding-bottom: 0.5rem;
        display: block;
        width: 100%;
    }

    .layui-input {
        border: 1px solid rgba(14, 15, 16, 0.16);
        border-radius: 0.5rem;

        padding: 0.75rem 1rem;
        outline: none;
        box-shadow: none;
        text-align: left;
        height: auto;
    }

    .layui-form-selected dl {
        margin-top: 0.375rem;
        margin-bottom: 0.375rem;
        border-radius: 0.5rem;

        dd.layui-this {
            color: #0E0F10;
        }
    }
}


@import './cgms_gen2_tablet.less';
@import './cgms_gen2_mobile.less';
